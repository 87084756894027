import React from 'react';
import Acordeon from "./Acordeon";
const LicenciaPublicitaria = () => {
  return (
    <div className="page-content">
   <Acordeon />
    </div>
  );
};

export default LicenciaPublicitaria;