
//informatica@alcaldialaunion.gob.sv
import ContactenosContenido from "../componentes/ContactenosContenido";
const Contactenos = () => {
  return (
    
    <div className="page-content">
    
    <ContactenosContenido />
  
    </div>
  );
};

export default Contactenos;