import React from 'react';

function Juridicos() {
  return (
    <div className="page-content">
      <h1>Jurídicos</h1>
      <p>Aquí va la información sobre los servicios jurídicos.</p>
    </div>
  );
}

export default Juridicos;
