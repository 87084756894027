import React from 'react';

const PortalTransparencia = () => {
  return (
    <div className="page-content" >
      <h1>Portal de Transparencia</h1>
      <p>Contenido del portal de transparencia...</p>
    </div>
  );
};

export default PortalTransparencia;