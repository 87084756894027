import React from 'react';
import MapaOriente from "./MapaOriente";
const Distritos = () => {
  return (
    <div className="page-content">
     <MapaOriente />
    </div>
  );
};

export default Distritos;