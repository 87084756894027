import React from 'react';

function Tributario() {
  return (
    <div className="page-content">
      <h1>Tributario</h1>
      <p>Aquí va la información sobre los servicios tributarios.</p>
    </div>
  );
}

export default Tributario;
