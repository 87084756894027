import React from 'react';
import InstitucionContenido from '../componentes/InstitucionContenido';

const institucion = () => {
  return (
    <div className="page-content">
    <InstitucionContenido />
    </div>
  );
};

export default institucion;