import React from 'react';

function LicenciasMatriculas() {
  return (
    <div className="page-content">
      <h1>Licencias y Matrículas</h1>
      <p>Aquí va la información sobre licencias y matrículas.</p>
    </div>
  );
}

export default LicenciasMatriculas;
