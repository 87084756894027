import React from 'react';
import Acordeon from "./Acordeon";
const Denuncia = () => {
  return (
    <div className="page-content">
    <Acordeon />
    </div>
  );
};

export default Denuncia;