import React from 'react';
import Acordeon from "./Acordeon";
const ConstanciaCatastral = () => {
  return (
    <div className="page-content">
    <Acordeon />
    </div>
  );
};

export default ConstanciaCatastral;