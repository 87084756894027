import React from 'react';

function Cementerio() {
  return (
    <div className="page-content">
      <h1>Cementerio</h1>
      <p>Aquí va la información sobre los servicios de cementerio.</p>
    </div>
  );
}

export default Cementerio;
