import React from 'react';
import Acordeon from "./Acordeon";
const DiligenciaVoluntaria = () => {
  return (
    <div className="page-content">
    <Acordeon />
    </div>
  );
};

export default DiligenciaVoluntaria;